.container {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 48px;
}

.table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 5%;
  border-radius: 32px;
}

.tableTitle {
  background: linear-gradient(to right, #55606b62, #4b89a863);
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  white-space: nowrap;
  font-size: 1.17em;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  cursor: pointer;
  border-color: #2728294f;
}

.tableTitleDisabled {
  background: linear-gradient(to right, #55606b62, #4b89a863);
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  white-space: nowrap;
  font-size: 1.17em;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  border-color: #2728294f;
}

.pair {
  width: 20%;
  padding: 24px;
  align-self: flex-start;
}

@media screen and (max-width: 1280px) {
  .container {
    width: 80%;
  }
  .pair {
    width: 22.5%;
  }
}

.justOnePair {
  width: 20%;
  padding: 24px;
  position: relative;
  right: 31%;
  align-self: flex-start;
}

.center {
  text-align: center;
}

.personContainer {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.person {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileImg {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0px 16px;
  aspect-ratio: 1/1;
}

.header {
  height: 43px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto;
  border-radius: 32px;
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #55606b62,
    #4b89a863
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #55606b62,
    #4b89a863
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

hr {
  border: 1px solid rgba(255, 255, 255, 0.466);
}

.deleteButton {
  border: 1px solid white;
  border-radius: 40%;
  outline: none;
  color: white;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  margin: 0px 16px;
}

.editButtonEnabled {
  position: absolute;
  top: 0px;
  right: -16px;
  padding: 12px;
  border: 1px solid rgb(212, 212, 212);
  border-radius: 32px;
  outline: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.356);
  text-align: center;
  cursor: pointer;
  margin: 0px 16px;
  justify-self: flex-end;
}

.editButtonDisabled {
  position: absolute;
  top: 0px;
  right: -16px;
  padding: 12px;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 32px;
  outline: none;
  color: white;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  margin: 0px 16px;
  justify-self: flex-end;
}

.modalTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalTitleContainer p {
  font-size: 1.3em;
}

.previewImg {
  width: 75%;
  height: auto;
  border-radius: 8px;
}
