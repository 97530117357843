.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 85%;
  margin: 18px auto;
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 87%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 90%;

  }
}


.inviteViewResponsive{
  margin-top: 24px;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 850px) {
  .inviteViewResponsive{
    flex-direction: column;
  }
}
