.container {
  background-size: contain;
  background: linear-gradient(
      0deg,
      rgba(0, 9, 41, 0.8) 0%,
      rgba(0, 9, 41, 1) 100%
    ),
    url('../../assets/images/background.jpeg') no-repeat;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
