.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 12px 0;
}

.text {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 35px;
}

@media only screen and (max-width: 375px) {
  .text {
    font-size: 30px;
  }
}
@media only screen and (max-width: 320px) {
  .text {
    font-size: 25px;
  }
}
