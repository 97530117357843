.header {
    background-color: rgb(30, 214, 30);
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-start-start-radius: 36px;
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
}

.highlighter {
    color: rgba(12, 189, 12, 0.767);
}