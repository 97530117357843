.inputsContainer {
  top: 50%;
  left: 50%;
  padding: 24px;
  border-radius: 12px;

  position: absolute;

  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: var(--main-blue);
}

.backgroud {
  top: 0%;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  background-size: contain;
  background: linear-gradient(
      0deg,
      rgba(0, 9, 41, 0.8) 0%,
      rgba(0, 9, 41, 1) 100%
    ),
    url('../../assets/images/background.jpeg') no-repeat;
}

.btnContainer,
.inputsContainer {
  display: flex;
  align-items: center;
}

.btnContainer {
  width: 100%;
  margin: 12px;
  justify-content: space-around;
}

.mainError {
  width: 100%;
  text-align: center;
  color: var(--main-red);
}
