.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  width: 60%;
  height: 30px;
  margin: 12px 0px;
  /* min-width: 850px; */
  padding: 6px 24px;

  background: var(--main-text);
}

.container > div {
  display: flex;
  color: var(--black);
  /* align-items: center; */
  justify-content: space-around;
}

.text {
  width: 80%;
}

.text > div {
  margin: 0 6px;
  width: 33.33%;
}

.button {
  margin: 0 6px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--main-text);
}

.img_icon {
  width: 20px;
}

.btnContainer {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1150px) {
  .text {
    flex-direction: column-reverse;
  }
  .container {
    height: 90px;
    width: 90%;
    flex-wrap: wrap;
  }
  .btnContainer {
    flex-direction: column;
    justify-content: flex-end;
  }
  .button {
    margin: 10px 6px;
  }
}
