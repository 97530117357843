.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload input {
  margin-bottom: 48px;
}

.upload button {
  margin: 32px 0px;
}

.container {
  width: 75%;
  height: 100px;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container p {
  width: 20%;
  padding: 32px;
  margin: 0px;
  overflow-wrap: break-word;
  text-overflow: clip;
  border: 1px solid rgba(255, 255, 255, 0.068);
}
