.container,
.item {
  display: flex;
  margin: 12px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.container {
  width: 50%;
  margin: 24px auto;
}

.item {
  width: 40%;
  margin: 12px 24px;
  flex-direction: column;
}

.text {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;
}

.line {
  width: 70%;
  height: 1.5px;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    flex-direction: column;
  }

  .item {
    width: 100%;
  }

  .line {
    max-width: 220px;
  }
}
