:root {
  --main-red: #ff062e;
  --main-blue: #0e2547;
  --main-bg-color: #000929;
  --middle-blue: #235075;
  --ligth-blue: #62d0ff;
  --main-text: #fff;
  --card-blue: #dbf4ff;
  --black: #2c2c2c;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--main-bg-color);
  color: var(--main-text);
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

::-moz-placeholder {
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
