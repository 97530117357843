.container {
  background: var(--main-text);
  background: linear-gradient(0deg, var(--main-text) 0%, var(--card-blue) 75%);

  margin: 12px;
  padding: 18px;
  padding-left: 36px;

  border-radius: 12px;
  cursor: pointer;
  height: 96px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.clicked {
  color: #bac3cbb8;
  margin: 0;
  padding: 0;
}

.text {
  color: #3e49cb;
  margin: 0;
  padding: 0;
}

.year {
  margin: 6px 0px;
  padding: 0;

  font-weight: bold;
  font-size: 18px;
  color: var(--black);
}
