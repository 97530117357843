.input {
  border: none;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  width: 100%;
  height: 30px;
  margin: 12px 0;
  font-size: 16px;
  padding: 6px 24px;
}

.error {
  width: 100%;
  text-align: center;
  color: var(--main-red);
}

@media only screen and (max-width: 768px) {
  .input {
    width: 75% !important;
  }
}
