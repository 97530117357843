.container {
  z-index: 1;
  height: 65px;
  padding: 0 60px;
  margin-bottom: 48px;
  background: var(--main-blue);
}

.container,
.linkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 170px;
  cursor: pointer;
}

.links {
  text-decoration: none;
  text-transform: uppercase;

  margin: 0 18px;
  color: var(--ligth-blue);
}

.links:hover {
  color: var(--main-red);
}

@media only screen and (max-width: 600px) {
  .logo {
    border-bottom: 2px solid var(--main-red);
    padding-bottom: 6px;
    margin-bottom: 12px;
  }
  .container {
    padding-top: 12px;
    height: auto;
  }

  .links {
    margin: 6px 0;
  }

  .container,
  .linkContainer {
    flex-direction: column;
  }
}
