.btn {
  cursor: pointer;
  width: 154px;
  height: 44px;
  contain: none;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  text-transform: uppercase;
  display: flex;
  margin: 12px auto;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.04em;
  border-radius: 12px;
  outline: none;
  color: var(--main-text);
}

.primary {
  border: 0;
  background: var(--main-red);
}

.primary:hover {
  box-shadow: 0 0 20px rgba(255, 6, 47, 0.73);
}

.secondary {
  border: 1px solid var(--ligth-blue);
  background: #000929;
}

.secondary:hover {
  background: var(--middle-blue);
}
