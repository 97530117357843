.container {
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(93, 95, 104, 0.8) 0%,
    rgba(7, 15, 46, 0.616) 100%
  );

  transform: translate(-50%, -50%);
  position: fixed;

  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

.modal {
  background: var(--main-blue);
  width: 25%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-end-end-radius: 36px;
  border-start-start-radius: 36px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.closeIcon {
  width: 10%;
  cursor: pointer;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 32px;
}