.input,
.li {
  box-sizing: border-box;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  width: 60%;
  height: 41.98px;
  font-size: 16px;

  color: black;
  background: #f0f0f0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.input {
  margin: 0;
  margin-top: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.li {
  width: 100%;
  height: 48px;
  padding: 6px;
  text-align: center;
  border-top: 1px solid rgb(156, 155, 155);
}

.li:hover {
  background: var(--card-blue);
}

.drpodownlist {
  width: 60%;
  max-height: 450px;
  bottom: 12px;
  padding-top: 12px;
  background: #f0f0f0;
  overflow: scroll;
  overflow-x: hidden;
  position: relative;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

@media only screen and (max-width: 768px) {
  .input {
    width: 75% !important;
  }
}
